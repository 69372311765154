import './style.css';
import MindMap from 'simple-mind-map';
// @ts-ignore
import { createUid } from 'simple-mind-map/src/utils';
import { NodeToolbar } from './node-toolbar-plugin';
import Select from 'simple-mind-map/src/plugins/Select';
import Drag from 'simple-mind-map/src/plugins/Drag.js';
import RichText from 'simple-mind-map/src/plugins/RichText.js';
import theme from './theme';

let i = 0;
const createChildren = (level = 1) => {
  if (level > 3 || i > 500) return [];
  return Array(2)
    .fill(0)
    .map(() => {
      i++;
      return {
        data: { uid: createUid(), text: `${level}级节点` },
        children: createChildren(level + 1),
      };
    });
};

MindMap.usePlugin(NodeToolbar);
MindMap.usePlugin(Select);
MindMap.usePlugin(Drag);
MindMap.defineTheme('wg-mind-map', theme);

const mindMap = new MindMap({
  // @ts-ignore
  el: document.querySelector('#app'),
  fit: true,
  mousewheelAction: 'zoom',
  hoverRectColor: 'var(--primary-brand-color)',
  themeConfig: {
    lineStyle: 'straight',
    lineRadius: 10,
  },
  data: {
    data: {
      text: '根节点',
    },
    children: createChildren(),
  },
});

mindMap.setTheme('wg-mind-map');
