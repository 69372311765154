import { ForeignObject, SVG } from '@svgdotjs/svg.js';
import toolbar from './toolbar.html';

const ID = 'node-toolbar';

export class NodeToolbar {
  constructor({ mindMap }) {
    this.mindMap = mindMap;
    this.el = SVG('<div>').id(ID).css('position', 'fixed');
    this.bar = this.toolbar();
    this.render();
    this.width = this.el.node.offsetWidth;
    this.height = this.el.node.offsetHeight;
    this.textEdit = false;
    this.hide();
    this.bindEvent();
    this.bindToolbarEvent();
  }

  bindEvent() {
    this.handleNodeActive = this.handleNodeActive.bind(this);
    this.handleBeforeShowTextEdit = this.handleBeforeShowTextEdit.bind(this);
    this.handleHideTextEdit = this.handleHideTextEdit.bind(this);

    this.mindMap.on('node_active', this.handleNodeActive);
    this.mindMap.on('before_show_text_edit', this.handleBeforeShowTextEdit);
    this.mindMap.on('hide_text_edit', this.handleHideTextEdit);
  }

  unBindEvent() {
    this.mindMap.off('node_active', this.handleNodeActive);
    this.mindMap.off('before_show_text_edit', this.handleBeforeShowTextEdit);
    this.mindMap.off('hide_text_edit', this.handleHideTextEdit);
  }

  bindToolbarEvent() {
    console.log(this.bar);
    this.bar.children().each(item => {
      item.on('click', e => {
        const action = e.currentTarget.getAttribute('data-action');
        if (!action) return;
        this[action]?.(e);
      });
    });
  }

  sibling() {
    this.mindMap.execCommand('INSERT_NODE');
  }
  child() {
    this.mindMap.execCommand('INSERT_CHILD_NODE');
  }
  relationship() {}
  dimension() {}
  attr() {}
  delete() {}

  handleNodeActive(_, activeNodeList) {
    const node = activeNodeList[0];
    if (activeNodeList.length > 1 || !node || node.isRoot || this.textEdit) {
      this.hide();
      return;
    }
    this.node = node;
    this.show();
    this.move();
  }

  handleBeforeShowTextEdit() {
    this.textEdit = true;
    this.hide();
  }

  handleHideTextEdit() {
    this.textEdit = false;
  }

  move(x, y) {
    if (x && y) {
      this.el.css({ left: x + 'px', top: y + 'px' });
    } else {
      const rect = this.node.shapeNode.node.getBoundingClientRect();
      const xA = rect.left + rect.width / 2 - this.width / 2;
      const yA = rect.top + rect.height + 20;
      this.el.css({ left: xA + 'px', top: yA + 'px' });
    }
  }

  show() {
    this.el.show();
  }

  hide() {
    this.el.hide();
    this.node = null;
  }

  toolbar() {
    const bar = SVG(toolbar);
    bar.css({
      display: 'inline-flex',
      'align-items': 'center',
      'text-align': 'center',
      'font-size': '12px',
      color: '#262833',
      'border-radius': '4px',
      border: '1px solid #E8EAEC',
      background: '#fff',
      'box-shadow':
        '0px 3px 10px 0px rgba(38, 40, 51, 0.10), 0px 0px 1px 0px rgba(38, 40, 51, 0.10)',
      width: 'max-content',
    });

    bar.children().css({
      padding: '4px 12px',
      cursor: 'pointer',
      'flex-shirk': 0,
    });

    bar.find('.border-right').css({ 'border-right': '1px solid #E8EAEC' });

    return bar;
  }

  render() {
    SVG('body').add(this.el.add(this.bar));
  }

  beforePluginRemove() {
    this.el.remove();
    this.unBindEvent();
  }

  beforePluginDestroy() {
    this.unBindEvent();
    this.el.remove();
  }
}
